/* eslint-disable react/no-danger */
import React from 'react';
import PropTypes from 'prop-types';

import { FormInput } from './patterns/formInput';

export const ContactForm = ({ title, introCopy }) => (
  <div className="contact-page__content-wrapper" key="form">
    <div dangerouslySetInnerHTML={{ __html: title }} />
    <div dangerouslySetInnerHTML={{ __html: introCopy }} />

    <form
      name="contact"
      className="form"
      method="POST"
      action="/contact-neue#success"
      data-netlify="true"
      data-netlify-honeypot="bot-field"
    >
      <input type="hidden" name="form-name" value="contact" />
      <ul className="form__list">
        <li className="form__item form__item--half">
          <FormInput
            id="firstName"
            label="First Name"
            labelClass="visuallyhidden"
            inputClass="form__input"
            placeholder="first name"
          />
        </li>
        <li className="form__item form__item--half">
          <FormInput
            id="lastName"
            label="Last Name"
            labelClass="visuallyhidden"
            inputClass="form__input"
            placeholder="last name"
          />
        </li>
        <li className="form__item form__item--half">
          <FormInput
            id="email"
            label="Email Address"
            labelClass="visuallyhidden"
            inputClass="form__input"
            placeholder="email address"
          />
        </li>
        <li className="form__item form__item--half">
          <FormInput
            id="subject"
            label="Subject"
            labelClass="visuallyhidden"
            inputClass="form__input"
            placeholder="subject"
          />
        </li>
        <li className="form__item form__item--full">
          <FormInput
            id="message"
            label="Message"
            labelClass="visuallyhidden"
            inputClass="form__input"
            placeholder="message"
            type="textarea"
          />
        </li>
        <li className="form__item form__item--full submit">
          <input
            type="submit"
            value="Submit"
            className="form__input form__input--submit"
          />
        </li>
      </ul>
    </form>
  </div>
);

ContactForm.propTypes = {
  introCopy: PropTypes.string,
  title: PropTypes.string,
};

ContactForm.defaultProps = {
  introCopy: '',
  title: '',
};
