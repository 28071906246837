/* eslint-disable react/no-danger */
import React from 'react';
import PropTypes from 'prop-types';

export const ContactSuccess = ({ successTitle, successCopy, successLink }) => (
  <div className="contact-page__success" key="success">
    <div>
      <div dangerouslySetInnerHTML={{ __html: successTitle }} />
      <div dangerouslySetInnerHTML={{ __html: successCopy }} />

      <a href="/" className="contact-page__success-link">
        {successLink}
      </a>
    </div>
  </div>
);

ContactSuccess.propTypes = {
  successTitle: PropTypes.string,
  successCopy: PropTypes.string,
  successLink: PropTypes.string,
};

ContactSuccess.defaultProps = {
  successTitle: '',
  successCopy: '',
  successLink: '',
};
