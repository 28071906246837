import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { graphql } from 'gatsby';

import { Layout } from '../components/layout';
import SEO from '../components/seo';

import { ContactForm } from '../components/contactForm';
import { ContactSuccess } from '../components/contactSuccess';

const ContactNeue = ({ data, location }) => {
  const contactData = data.neueamsterdam.page.contactPage;
  const [showSuccess, setShowSuccess] = useState(false);

  useEffect(() => {
    if (location.hash === '#success') {
      setShowSuccess(true);
    }
  }, [location.hash]);

  return (
    <Layout>
      <SEO title="Contact Neue" />
      <div className="container">
        <article className="contact-page">
          {showSuccess ? (
            <ContactSuccess
              successTitle={contactData.successTitle}
              successCopy={contactData.successCopy}
              successLink={contactData.successLink}
            />
          ) : (
            <ContactForm
              title={contactData.title}
              introCopy={contactData.introCopy}
            />
          )}
        </article>
      </div>
    </Layout>
  );
};

ContactNeue.propTypes = {
  data: PropTypes.shape({
    neueamsterdam: PropTypes.shape({
      page: PropTypes.shape({
        contactPage: PropTypes.shape({
          title: PropTypes.string,
          introCopy: PropTypes.string,
          successTitle: PropTypes.string,
          successCopy: PropTypes.string,
          successLink: PropTypes.string,
        }),
      }),
    }),
  }).isRequired,
  location: PropTypes.shape({
    hash: PropTypes.string,
  }).isRequired,
};

export const query = graphql`
  query {
    neueamsterdam {
      page(id: 1429, idType: DATABASE_ID) {
        contactPage {
          title
          introCopy
          successTitle
          successCopy
          successLink
        }
      }
    }
  }
`;

export default ContactNeue;
